import React, { Component } from 'react';
import styled from 'styled-components';
import loadable from '@loadable/component';

const InfoPage = loadable(() => import('../components/InfoPage'));
const ContentBox = loadable(() =>
  import('../components/sub-components/contentBox')
);
const EmailForm = loadable(() => import('../components/sub-components/emailForm'));

const LocalContentBox = styled(ContentBox)`
  h2 {
    font-weight: 500;
    font-size: 20px;
  }
  h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
    text-align: left;
    text-transform: unset;
    letter-spacing: unset;
  }
  p {
    font-size: 16px;
  }
  strong {
    font-weight: 600;
  }
`;

class Commmissions extends Component {
  render() {
    return (
      <InfoPage
        title="Jennifer Mone Hill Artist - Commission Art"
        description="How to commission artwork from Jennifer Mone Hill."
        keywords={[
          'buy art',
          'buy Jennifer Moné Hill art',
          'Jen Hill Artist',
          'Jennifer Mone Hill',
          'Jen Hill',
          'Jen Hill Artist',
          'how to purchase',
          'corporate art',
          'interior art',
          '2D art',
          'paintings',
          'buy paintings',
          'buy modern art',
          'buy abstract art',
          'availability',
          'commission art',
          'one of a kind',
          'original art',
          'buy original art',
        ]}
        imgSrc="Commission-Page-pics-800.jpg"
        imgSrc2x="Commission-Page-pics-1600.jpg"
        alt="Roseate by Jennifer Moné Hill"
      >
        <LocalContentBox>
          <h2>Commission your own one of a kind piece</h2>
          <h3>Get Started!</h3>
          <p>
            <a href="mailto:jmonehill@gmail.com?subject=Commission Inquiry">
              Email
            </a>{' '}
            Jennifer to set up a free consultation. We want to hear from you!
            Jennifer typically responds to your email the same day, and if you’d
            rather talk on the phone, just email with your phone number, and
            best time to call.
            <br />
            <br />
            Jennifer will make the commission process easy for you. You’ll have
            a painting that was made especially for you, with you in mind, the
            size you need and the colors you choose. Let’s get started today.
          </p>
          <h3>Collaboration</h3>
          <p>
            We love to work with you! We’ll either meet in person (masks and
            social distancing protocols required), or via FaceTime, Zoom,
            WhatsApp, to collaborate on your piece. Your comfort and safety is
            number one.
            <br />
            <br />
            We’re happy to work with design professionals, as well as directly
            with art collectors.
            <br />
            <br />
            Things to consider are size of canvas, colors that make your heart
            sing, location of the final piece, and timeline.
            <br />
            <br />
            Your input is appreciated. Many times an existing painting of
            Jennifer’s is a starting point for a new commission. Feel free to
            supply paint/fabric color swatches, or any inspirations you’d like
            to share. For example, images of your space, accessories, a book, or
            song.
          </p>
          <h3>Cost</h3>
          <p>
            Your commissioned piece is priced the same as other artwork, giving
            you the ability to customize to your desired size and colors. Please
            know that there is something left to the muses when creating
            abstract art. No two are alike, and that’s a good thing!
            <br />
            <br />
            50% deposit required. Once you’ve decided to commission a painting,
            we take a deposit to ensure that you are ready for Jennifer to start
            on your project. The remaining 50% is due at delivery, and sales tax
            applies. Credit cards are gladly accepted.
          </p>
          <h3>Delivery</h3>
          <p>
            Local purchases may be hand delivered, or picked up in Danbury, CT.
            Free delivery for our neighbors in Fairfield County, Westchester
            County, and Putnam County for sizes up to 72x40”. Once your painting
            is ready we’ll contact you to set up a delivery time that works for
            you.
            <br />
            <br />
            Let us know if you need help with installing your new art. We’re
            here to help.
          </p>
          <h3>Happy You Happy Artist</h3>
          <p>
            Purchasing art directly from the artist is the most satisfying way
            to go. You’ll feel good knowing you’re helping support the arts in
            the best way possible. We’ll feel good knowing we’ve made your space
            unique to you, happier, more you.
          </p>
          <h3>Start the Conversation</h3>
          <p>
            Email us about your commission. Jennifer typically responds the same
            day.
          </p>
          <br />
          <EmailForm pageEmbeddedIn="commmissions" />
        </LocalContentBox>
      </InfoPage>
    );
  }
}

export default Commmissions;
